html {
    overflow: hidden;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    background-color: #01040c;
}

body {
    margin: 0;
    font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
