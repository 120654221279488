.container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.hero {
    height: 100vh;
    display: flex;
}

.about {
    height: 1150px;
    display: flex;
    background-color: #6f1c56;
}

.project {
    height: 800px;
    display: flex;
    background-color: #030f28;
}

.experience {
    height: 950px;
    display: flex;
    background-color: #6f1c56;
}

.contact {
    height: 100vh;
    display: flex;
    background-color: #030f28;
}

@media (min-width: 768px) {
    .container {
        width: 100%;
        height: 100%;
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        height: 100vh;
    }

    .hero {
        height: 100vh;
        display: flex;
        scroll-snap-align: start;
    }

    .about {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        background-color: #6f1c56;
    }

    .project {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        background-color: #030f28;
    }

    .experience {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        background-color: #6f1c56;
    }

    .contact {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        background-color: #030f28;
    }
}
